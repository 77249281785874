<template>
    <div class="action-schedule">
        <div class="message m-b-8">
            <div class="message-top">
                <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                <span v-html="introduction.title" />
            </div>
            <div class="message-content">
                <div class="desc" v-html="introduction.desc" />
                <div class="grey-box">
                    <div class="user-profile">
                        <img :src="photoUrl" alt="" />
                        <div class="profile-info">
                            <div class="age" v-html="age" />
                            <div class="region" v-html="content.region" />
                            <div class="job" v-html="content.job" />
                        </div>
                    </div>
                </div>
                <div v-if="gender === 1" class="grey-box m-t-8">
                    <div class="title-grey">약속 내용</div>
                    <!--   하드코딩  -->
                    <div class="content-grey">
                        <p class="m-b-4">{{ time }}</p>
                        <p>{{ content.meet_place }}에서 {{ meetType }}예정</p>
                    </div>
                </div>
                <div v-else class="grey-box m-t-8">
                    <ScheduleProgress :mode="gender === 1 ? 'a' : 'b'" :selected="2" />
                </div>
                <!-- 분기처리 추가 여자, 횟수제-->
                <div v-if="userRatePlans.onGoing && gender === 1" class="info m-t-8 m-b-16">
                    <img class="m-r-4" src="@/assets/images/icons/info_pink.png" width="16px" height="16px" />

                    <div class="text" v-html="'약속 확정시 만남권 1회가 사용됩니다'" />
                </div>
            </div>
        </div>
        <BottomButton
            v-if="gender === 1"
            :disabled="disabled"
            @click="onClickConfirm"
            label="약속 최종 확정"
            :nonFixed="true"
        />
    </div>
</template>

<script>
import ScheduleProgress from './ScheduleProgress'
import scheduleService from '@/services/schedule'

export default {
    name: 'ActionScheduleConfirm',
    props: ['message'],
    components: {
        ScheduleProgress,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return `${this.$options.filters.asAge(this.content.birthday)}세`
        },
        time() {
            const dateConfirmed = this.content.date_confirmed
            const date = this.$moment(dateConfirmed).format('YY/MM/DD')
            const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
            const time = this.$moment(dateConfirmed).format('A h시 mm분')

            return `${date} ${this.$translate(day)}, ${time}`
        },
        meetType() {
            const fixed = ['coffee', 'meal']

            return fixed.includes(this.content.meet_type_confirmed)
                ? this.$translate(this.content.meet_type_confirmed.toUpperCase())
                : '커피 또는 식사'
        },
        disabled() {
            return this.content.status === 1
        },
        gender() {
            return this.$store.getters.me.gender
        },
        introduction() {
            return {
                title: this.gender === 1 ? '확정 일정이 도착했어요!' : '잠시만 기다려주세요!',
                desc:
                    this.gender === 1
                        ? '일정 확인하시고 아래 확정버튼을 눌러주시면 최종 확정됩니다!'
                        : '여성분이 일정을 확인하시면 자동으로 약속이 최종 확정됩니다!',
            }
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
        hasUnpaid() {
            return (this.me || {}).products.some(p => {
                return p.product_id === 62
            })
        },
    },
    methods: {
        async onClickConfirm() {
            if (this.userRatePlans.onGoing || this.hasUnpaid) {
                const payload = { product_id: 1 }
                payload[`${this.$store.getters.me.gender === 1 ? 'a' : 'b'}_message_id`] = this.message.id

                try {
                    await scheduleService.createSchedule(this.content.schedule_id, payload)
                    this.$store.dispatch('loadChat', { chatId: this.message.chat_id, force: true })
                    this.$store.dispatch('loadMe')
                } catch (e) {
                    this.$toast.error(e.data.msg)
                }
            } else {
                this.$modal.basic({
                    title: '프로그램 이용이 만료되었습니다',
                    body: '약속을 진행하려면 프로그램을 구매해주세요',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            }

            this.$stackRouter.pop()

            // this.$stackRouter.push({
            //     name: 'SchedulePayPage',
            //     props: {
            //         userInfo: {
            //             photoUrl: this.photoUrl,
            //             age: this.age,
            //             region: this.content.region,
            //             job: this.content.job,
            //             message: this.message,
            //             schedule_id: this.content.schedule_id,
            //             date_confirmed: this.content.date_confirmed,
            //             meet_place: this.content.meet_place,
            //             meet_type_confirmed: this.content.meet_type_confirmed,
            //         },
            //     },
            // })
        },
    },
}
</script>
<style scoped lang="scss">
.desc {
    font-size: 14px;
}
.info {
    background: rgba(255, 61, 107, 0.07);
    border-radius: 8px;
    padding: 4px 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #111111;
    display: flex;
    align-items: center;
}
</style>
